import ComboMenu from './combo-menu';
import { createNanoEvents, type Emitter, type Unsubscribe } from 'nanoevents';
import { getMediaQueryForBreakpointUp } from './media-queries';

const desktopMediaQuery = getMediaQueryForBreakpointUp('xl');

interface MenuLayerEvents {
    open: () => void
}

class MenuLayer {
    private readonly toggleButtons!: HTMLCollection;
    private readonly bodyClass!: string[];
    private readonly emitter!: Emitter;
    private isLayerOpen = false;

    constructor (toggleButtonClass: string, bodyClass: string[]) {
        this.toggleButtons = document.getElementsByClassName(toggleButtonClass);
        this.bodyClass = bodyClass;
        this.emitter = createNanoEvents<MenuLayerEvents>();

        for (const button of this.toggleButtons) {
            button.addEventListener('click', (event) => {
                this.toggle();
            });
        }
    }

    on<E extends keyof MenuLayerEvents> (event: E, callback: MenuLayerEvents[E]): Unsubscribe {
        return this.emitter.on(event, callback);
    }

    public isOpen (): boolean {
        return this.isLayerOpen;
    }

    public open (): void {
        if (this.isLayerOpen) {
            return;
        }

        for (const button of this.toggleButtons) {
            button.setAttribute('aria-expanded', 'true');
        }
        document.body.classList.add(...this.bodyClass);

        this.isLayerOpen = true;
        this.emitter.emit('open', this);

        document.addEventListener('click', this.handleOutsideClick.bind(this));
    }

    public close (): void {
        if (!this.isLayerOpen) {
            return;
        }

        for (const button of this.toggleButtons) {
            button.setAttribute('aria-expanded', 'false');
        }
        document.body.classList.remove(...this.bodyClass);

        this.isLayerOpen = false;

        document.removeEventListener('click', this.handleOutsideClick.bind(this));
    }

    public toggle (): void {
        if (this.isLayerOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    private handleOutsideClick (event: Event): void {
        const layerElement = document.querySelector('.header-navigation-container') as HTMLElement;
        const isClickInsideToggle = Array.from(this.toggleButtons).some((button) => button.contains(event.target as Node));

        if (!layerElement.contains(event.target as Node) && !isClickInsideToggle) {
            this.close();
        }
    }
}

export default function initHeader (): void {
    const mainMenuLayer = ComboMenu.attach(
        document.getElementsByClassName('header-menu')[0] as HTMLElement,
        { desktopMediaQuery }
    );
    const newsletterLayer = new MenuLayer('header-newsletter-button', ['newsletter-open', 'desktop-flyout-open']);
    const desktopLayers = [
        mainMenuLayer,
        new MenuLayer('header-search-button', ['search-open']),
        newsletterLayer
    ];

    // close other layers when the user opens another
    desktopLayers.forEach((desktopLayer) => {
        // @ts-expect-error: TS2349 because MenuLayerEvents and ComboMenuEvents are compatible for "open", only TS does not know
        desktopLayer.on('open', (targetDesktopLayer) => {
            // don't close the main menu (simplified to "anything") for the newsletter on mobile
            if (!(targetDesktopLayer === newsletterLayer && !desktopMediaQuery.matches)) {
                desktopLayers.forEach((desktopLayer) => {
                    if (targetDesktopLayer !== desktopLayer) {
                        desktopLayer.close();
                    }
                });
            }
        });
    });

    // simply close all layers when switching between mobile and desktop: will create focus havoc for keyboard-only users
    desktopMediaQuery.addEventListener('change', (event) => {
        desktopLayers.forEach((desktopLayer) => {
            desktopLayer.close();
        });
    });
}
