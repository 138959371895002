function adjustPageHeaderMarginAccordingScilogHeader (): void {
    const scilogMenu = document.querySelector('.scilog-header-navigation-container') as HTMLElement;
    if (scilogMenu === null || scilogMenu === undefined) return;

    const pageHeader = document.querySelector('.page-header') as HTMLElement;
    if (pageHeader === null || pageHeader === undefined) return;

    const menuHeight = scilogMenu.offsetHeight;

    pageHeader.style.marginTop = `${menuHeight}px`;
}

export function adjustScilogHeader (): void {
    adjustPageHeaderMarginAccordingScilogHeader();
    window.addEventListener('resize', () => {
        adjustPageHeaderMarginAccordingScilogHeader();
    });
}
